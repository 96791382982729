import * as React from "react";
import { graphql } from "gatsby";
import PageContent from "../../../components/layout/content";
import Layout from "../../../components/layout/layout";
import PageHeader from "../../../components/layout/header/pageHeader";
import { useMemo } from "react";
import useLayoutGeneration from "../../../core/hooks/useLayoutGeneration";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import richTextOptions from "../../../components/richTextOptions/richTextOptions";
import "../../../theme/style.scss";
import {
  FrameBlock100,
  FrameSectionRow,
} from "../../../components/basics/frame/frameBlocks";

const GeneralPage = ({ data }) => {
  const pageData = useMemo(() => data.contentfulOldal, [data]);
  const contents = useMemo(
    () => pageData?.tartalomTpus?.tartalom || [],
    [pageData],
  );

  const [generator, resolver] = useLayoutGeneration();

  const generatedContent = useMemo(() => {
    const generatedList = generator(contents);
    const componentList = generatedList.map(resolver);
    return pageData.slug === "terms&conditions" ? "" : componentList;
  }, [contents, generator, resolver, pageData]);

  return (
    <Layout>
      <PageHeader pageData={pageData} />
      <PageContent>
        {generatedContent}
        {pageData.tartalomTpus.richText && (
          <FrameSectionRow>
            <FrameBlock100>
              {renderRichText(pageData.tartalomTpus.richText, richTextOptions)}
            </FrameBlock100>
          </FrameSectionRow>
        )}
      </PageContent>
    </Layout>
  );
};
//hozzá kell adni az összes építőblock típust
export const query = graphql`
  query general($contentfulId: String) {
    contentfulOldal(contentful_id: { eq: $contentfulId }) {
      slug
      cm
      alcm
      kategria {
        slug
      }
      tartalomTpus {
        ... on ContentfulGeneralTartalomTipus {
          id
          header {
            bortkp {
              gatsbyImageData(layout: CONSTRAINED)
              url
            }
            lers
            title
            primaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
            secondaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
          }
          tartalom {
            ... on ContentfulSzekcio {
              token: contentful_id
              szekcicm {
                raw
              }
              ComponentContent: tartalom {
                ... on ContentfulFaq {
                  id
                  questionBlock: krdsek {
                    cm
                    lers
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulCtaBox {
                  cm
                  szveg
                  ctaGomb {
                    ... on ContentfulCtaGomb {
                      ctaGombLink {
                        ... on ContentfulOldal {
                          slug
                          kategria {
                            slug
                          }
                        }
                        ... on ContentfulOldalKategoria {
                          slug
                        }
                      }
                      ctaGombSzveg
                      externalCtaGombLink
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulYoutubeVideoBeagyazas {
                  id
                  internal {
                    type
                  }
                  contentfulCm
                  transparentImage
                  bortkp {
                    contentful_id
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                  yotubeVideoUrl
                }
                ... on Contentful2OszloposSor {
                  id
                  alcm
                  cm
                  kp {
                    contentful_id
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                  nagythatKp
                  internal {
                    type
                  }
                  oszlopTartalom: tartalom {
                    raw
                  }
                  ctaGomb {
                    ... on ContentfulCtaGomb {
                      ctaGombLink {
                        ... on ContentfulOldal {
                          slug
                          kategria {
                            slug
                          }
                        }
                        ... on ContentfulOldalKategoria {
                          slug
                        }
                      }
                      ctaGombSzveg
                      externalCtaGombLink
                    }
                  }
                }
              }
              themeColoured
              internal {
                type
              }
            }
            ... on ContentfulKiemeltTartalom {
              tartalomKategria {
                kategria
                slug
                internal {
                  type
                }
              }
              cm
              alcm
              ComponentContent: tartalom {
                kategria {
                  slug
                  kategria
                }
                cm
                alcm
                slug
                tartalomTpus {
                  ... on ContentfulElerhetosegeinkTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulServicesTartalomTpus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulPricingTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulInstructorsTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                  ... on ContentfulGeneralTartalomTipus {
                    id
                    header {
                      bortkp {
                        gatsbyImageData(layout: CONSTRAINED)
                        url
                      }
                    }
                  }
                }
                internal {
                  type
                }
              }
              internal {
                type
              }
            }
          }
          richText {
            raw
          }
        }
      }
    }
  }
`;

export default GeneralPage;

// query general($contentfulId: String) {
//   contentfulOldal(contentful_id: { eq: $contentfulId }) {
//     slug
//     cm
//     alcm
//     kategria {
//       slug
//     }
//     tartalomTpus {
//       ... on ContentfulGeneralTartalomTipus {
//         id
//         header {
//           bortkp {
//             gatsbyImageData(layout: CONSTRAINED)
//             url
//           }
//           lers
//           title
//           primaryCta {
//             ... on ContentfulCtaGomb {
//               ctaGombLink {
//                 slug
//                 kategria {
//                   slug
//                 }
//               }
//               ctaGombSzveg
//             }
//             ... on ContentfulExternalCtAlink {
//               extCtaGombLink
//               ExtCtaGombSzveg
//             }
//             ... on ContentfulKategoriaCtaGomb {
//               ctaGombLink {
//                 slug
//               }
//               ctaGombSzveg
//             }
//           }
//           secondaryCta {
//             ... on ContentfulCtaGomb {
//               ctaGombLink {
//                 slug
//                 kategria {
//                   slug
//                 }
//               }
//               ctaGombSzveg
//             }
//             ... on ContentfulExternalCtAlink {
//               extCtaGombLink
//               ExtCtaGombSzveg
//             }
//             ... on ContentfulKategoriaCtaGomb {
//               ctaGombLink {
//                 slug
//               }
//               ctaGombSzveg
//             }
//           }
//         }
//         tartalom {
//           ... on ContentfulSzekcio {
//             token: contentful_id
//             szekcicm {
//               raw
//             }
//             ComponentContent: tartalom {
//               ... on ContentfulFaq {
//                 id
//                 questionBlock: krdsek {
//                   cm
//                   lers
//                 }
//                 internal {
//                   type
//                 }
//               }
//               ... on ContentfulCtaBox {
//                 cm
//                 szveg
//                 ctaGomb {
//                   ... on ContentfulCtaGomb {
//                     ctaGombLink {
//                       slug
//                       kategria {
//                         slug
//                       }
//                     }
//                     ctaGombSzveg
//                   }
//                   ... on ContentfulExternalCtAlink {
//                     extCtaGombLink
//                     ExtCtaGombSzveg
//                   }
//                   ... on ContentfulKategoriaCtaGomb {
//                     ctaGombLink {
//                       slug
//                     }
//                     ctaGombSzveg
//                   }
//                 }
//                 internal {
//                   type
//                 }
//               }
//               ... on ContentfulYoutubeVideoBeagyazas {
//                 id
//                 internal {
//                   type
//                 }
//                 contentfulCm
//                 transparentImage
//                 bortkp {
//                   contentful_id
//                   gatsbyImageData(layout: CONSTRAINED)
//                 }
//                 yotubeVideoUrl
//               }
//               ... on Contentful2OszloposSor {
//                 id
//                 alcm
//                 cm
//                 kp {
//                   contentful_id
//                   gatsbyImageData(layout: CONSTRAINED)
//                 }
//                 nagythatKp
//                 internal {
//                   type
//                 }
//                 oszlopTartalom: tartalom {
//                   raw
//                 }
//                 ctaGomb {
//                   ... on ContentfulCtaGomb {
//                     ctaGombLink {
//                       slug
//                       kategria {
//                         slug
//                       }
//                     }
//                     ctaGombSzveg
//                   }
//                   ... on ContentfulExternalCtAlink {
//                     extCtaGombLink
//                     ExtCtaGombSzveg
//                   }
//                   ... on ContentfulKategoriaCtaGomb {
//                     ctaGombLink {
//                       slug
//                     }
//                     ctaGombSzveg
//                   }
//                 }
//               }
//             }
//             themeColoured
//             internal {
//               type
//             }
//           }
//           ... on ContentfulKiemeltTartalom {
//             tartalomKategria {
//               kategria
//               slug
//               internal {
//                 type
//               }
//             }
//             cm
//             alcm
//             ComponentContent: tartalom {
//               kategria {
//                 slug
//                 kategria
//               }
//               cm
//               alcm
//               slug
//               tartalomTpus {
//                 ... on ContentfulElerhetosegeinkTartalomTipus {
//                   id
//                   header {
//                     bortkp {
//                       gatsbyImageData(layout: CONSTRAINED)
//                       url
//                     }
//                   }
//                 }
//                 ... on ContentfulServicesTartalomTpus {
//                   id
//                   header {
//                     bortkp {
//                       gatsbyImageData(layout: CONSTRAINED)
//                       url
//                     }
//                   }
//                 }
//                 ... on ContentfulPricingTartalomTipus {
//                   id
//                   header {
//                     bortkp {
//                       gatsbyImageData(layout: CONSTRAINED)
//                       url
//                     }
//                   }
//                 }
//                 ... on ContentfulInstructorsTartalomTipus {
//                   id
//                   header {
//                     bortkp {
//                       gatsbyImageData(layout: CONSTRAINED)
//                       url
//                     }
//                   }
//                 }
//                 ... on ContentfulGeneralTartalomTipus {
//                   id
//                   header {
//                     bortkp {
//                       gatsbyImageData(layout: CONSTRAINED)
//                       url
//                     }
//                   }
//                 }
//               }
//               internal {
//                 type
//               }
//             }
//             internal {
//               type
//             }
//           }
//         }
//         richText{
// 					raw
//         }
//       }
//     }
//   }
// }
